import { atom } from 'recoil';
import { Socket } from "socket.io-client";
import { ChannelInterface, PlayerInterface } from "../Interfaces";

const socketIoState = atom<Socket | null>({
	key:'SocketIoState',
	default: null,
	dangerouslyAllowMutability: true,
});

const loadingState = atom({
	key:'LoadingState',
	default: false
});

const channelState = atom<ChannelInterface | null>({
	key:'ChannelState',
	default: null
});

const playerState = atom<PlayerInterface[]>({
	key:'playerState',
	default: []
});

const nameModalState = atom({
	key: 'NameModalState',
	default: false,
});

const nameState = atom({
	key: 'NameState',
	default: '',
});

const spectatorModeState = atom({
	key: 'SpectatorModeState',
	default: false,
});

const showCardsState = atom({
	key: 'ShowCardsState',
	default: false,
});

const godModeState = atom({
	key: 'GodModeState',
	default: false,
});

const showCardsIntervalState = atom({
	key: 'ShowCardsIntervalState',
	default: false,
});

const meState = atom<PlayerInterface | null>({
	key: 'MeState',
	default: null,
});

export {
	channelState,
	loadingState,
	godModeState,
	showCardsState,
	showCardsIntervalState,
	socketIoState,
	nameModalState,
	nameState,
	spectatorModeState,
	playerState,
	meState
}
