import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Typography } from "antd";
import { AxiosResponse } from "axios";
import Konami from 'react-konami-code';
import Lottie from "lottie-react";
import useSound from 'use-sound';

import GodLike from '../../Assets/Sounds/godlike.mp3';
import MagicAnimation from '../../Assets/Animations/magic.json';

import { loadingState, socketIoState, channelState, playerState, nameModalState, spectatorModeState, nameState, meState, showCardsState, showCardsIntervalState, godModeState } from "../../States/GlobalStates";
import { PlayerInterface } from "../../Interfaces";
import SocketIoService from "../../Services/SocketService";
import ApiService from "../../Services/ApiService";
import PokerTable from "../../Components/PokerTable";
import PlayerModal from "../../Components/PlayerModal";
import VotingCards from "../../Components/VotingCards";

const {Title} = Typography;

const PokerGame: React.FC = () => {
	const params = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useRecoilState(loadingState);
	const [socketState, setSocketState] = useRecoilState(socketIoState);
	const [player, setPlayer] = useRecoilState(playerState);
	const [godMode, setGodMode] = useRecoilState(godModeState);
	const setMe = useSetRecoilState(meState);
	const setChannel = useSetRecoilState(channelState);
	const setName = useSetRecoilState(nameState);
	const setShowCardsState = useSetRecoilState(showCardsState);
	const setShowCardsIntervalState = useSetRecoilState(showCardsIntervalState);
	const setSpectatorMode = useSetRecoilState(spectatorModeState);
	const setNameModalOpen = useSetRecoilState(nameModalState);
	const [lottieVisible, setLottieVisible] = useState(false);
	const [play] = useSound(GodLike, {soundEnabled: true});

	const checkIfNameIsAvailable = () => {
		let localData = JSON.parse(localStorage.getItem(`${params?.channelSlug}`) || '{}');
		if (localData.name && socketState && socketState.connected) {
			// add player data if found
			let editedPlayer: PlayerInterface = JSON.parse(JSON.stringify(player));
			editedPlayer.name = localData?.name.slice(0, 20);
			editedPlayer.spectator = localData?.spectator;

			socketState?.emit('personChange', {...editedPlayer, id: socketState?.id});
			setName(localData.name);
			setSpectatorMode(localData.spectator);
			setNameModalOpen(false);
		} else {
			setNameModalOpen(true);
		}
	};

	const initializeGame = async () => {
		try {
			// fetch channel with informations
			const channel: AxiosResponse = await ApiService.fetchChannel(params.channelSlug || '');
			const channelResponse = channel.data;
			if (!channelResponse.pokerChannel) {
				console.info("No Channel found");
				navigate('/');
			}
			// set channel and votingSystem
			setChannel(channelResponse.pokerChannel);

			// check if name is already given in localstorage
			checkIfNameIsAvailable();

			// check if socket is active and enter poker room
			//console.log("Entering room");
			socketState?.emit('enter-room');
		} catch (error) {
			console.log(error);
			navigate('/');
		}
		setLoading(false);
	};

	// eslint-disable-next-line
	useEffect(() => setLoading(true), []);

	// reset all if component unmounts
	useEffect(() => () => {
		setLoading(false);
		setChannel(null);

		setMe(null);
		setName('');
		setChannel(null);
		setPlayer([]);

		// disconnect socket
		if (socketState && socketState.connected) {
			socketState?.disconnect();
			//console.log("disconnected");
			setSocketState(null);
		}
		// eslint-disable-next-line
	}, [socketState]);

	useEffect(() => {
		const initializeSocket = async () => {
			//console.log("Socket is connected");
			await initializeGame();
		};

		const init = () => {
			if (socketState) {
				socketState.on('update', (player: PlayerInterface[]) => {
					setPlayer(player);

					// find me
					const me = player.find(player => player.id === socketState?.id);
					if (me) {
						setMe(me);
					}

					// check if cards are shown
					if (me && me.showCards) {
						setShowCardsState(me.showCards);
						setShowCardsIntervalState(me.showCards);
					}
				});

				socketState.on('restartGame', () => {
					setShowCardsIntervalState(false);
					setShowCardsState(false);
				});

				if (!socketState.connected) {
					socketState.on('connect', () => {
						initializeSocket();
					});
				} else {
					initializeSocket();
				}
			} else {
				const socket = SocketIoService.getSocketIoConnection({query: {"roomId": params?.channelSlug}});
				setSocketState(socket);
			}
		};
		init();
		// eslint-disable-next-line
	}, [socketState]);

	return (
		<Fragment>
			{loading ? (
				<Title>Loading ...</Title>
			) : (
				<div style={{flex: '1 0 auto', display: 'flex', flexDirection: 'column'}}>
					<PokerTable/>
					<VotingCards/>
					<PlayerModal/>
					{(godMode && lottieVisible) && (
						<div style={{overflow: 'hidden', position: 'absolute', top: '0px', right: '0px', zIndex: '0', pointerEvents: 'none', width: '400px', height: '100vh'}}>
							<Lottie style={{pointerEvents: 'none', position: 'absolute', left: '-180px', width: '800px', height: '100vh'}} autoplay={true} animationData={MagicAnimation} loop={false} onComplete={() => {
								setLottieVisible(false);
							}}/>
						</div>
					)}
					<Konami className="" timeout={2000} action={() => {
						if (!godMode) {
							play();
							setLottieVisible(true);
						}
						setGodMode(!godMode);
					}}/>
				</div>
			)}
		</Fragment>
	);
};

export default PokerGame;
