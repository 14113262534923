import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './App';
import 'antd/dist/antd.min.css';
import './Styles/index.scss';

// register sentry error tracking
if(process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
		release: process.env.REACT_APP_SENTRY_RELEASE || 'latest'
	});
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(
	<RecoilRoot>
		<BrowserRouter>
			<App/>
		</BrowserRouter>
	</RecoilRoot>);
