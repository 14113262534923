import React, { Fragment, PropsWithChildren, ReactNode, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Space, Typography } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { PlayerInterface } from "../Interfaces";
import { godModeState, nameModalState, showCardsIntervalState, showCardsState } from "../States/GlobalStates";

const { Title } = Typography;

type PlayerProps = {
	player: PlayerInterface,
	editable: boolean,
	children?: ReactNode | undefined
}

const Player: React.FC<PropsWithChildren<PlayerProps>> = ({player, editable}) => {
	const showCards = useRecoilValue(showCardsState);
	const godMode = useRecoilValue(godModeState);
	const showCardsInterval = useRecoilValue(showCardsIntervalState);
	const setNameModalOpen = useSetRecoilState(nameModalState);

	useEffect(() => () => {
		setNameModalOpen(false);
	}, [setNameModalOpen]);

	return (
		<Space style={{padding: '10px 10px 0 10px'}} direction="vertical" align="center" key={player.id}>
			<div className={`card${(!godMode && !showCards) || (showCards && player.spectator) ? ' card--hidden' : ''}${player.spectator ? ' card--disabled' : ''}`}>
				<div className={`card__front${player.vote !== '' ? ' card__front--voted' : ''}`}/>
					<div className="card__back">
						{(showCards || godMode) && (
							<Fragment>{player.vote || ''}</Fragment>
						)}
					</div>
			</div>

			<Title level={5} style={{marginBottom: 0}}>
				{player.name || `New Player`}&nbsp;
				{(!showCardsInterval && !showCards && editable) && (
					<SettingOutlined className="settings" disabled={showCardsInterval} onClick={() => {
						if(showCardsInterval) return;
						setNameModalOpen(true);
					}}/>
				)}
			</Title>
		</Space>
	);
};

export default Player;
