import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, Select, Space, Typography } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";

import { VotingSystem } from "../Helper/Enum";
import { loadingState, socketIoState } from "../States/GlobalStates";

import SocketIoService from "../Services/SocketService";
import { useNavigate } from "react-router-dom";
import { ChannelInterface } from "../Interfaces";

const { Title } = Typography;

const NewGameForm: React.FC = () => {
	const navigate = useNavigate();
	const [channelName, setChannelName] = useState<string>('');
	const [votingSystem, setVotingSystem] = useState<string>(VotingSystem.one);
	const [loading, setLoading] = useRecoilState(loadingState);
	const setSocketState = useSetRecoilState(socketIoState);

	const startNewPlanning = () => {
		setLoading(true);
		const socket = SocketIoService.getSocketIoConnection();
		socket.emit('create-room', {title: channelName, votingSystem});

		socket.on('room', (channel: ChannelInterface ) => {
			setTimeout(() => {
				setLoading(false);
				navigate(`/poker/${channel.slug}`);
			}, 1000);
		});

		setSocketState(socket);
	};

	const handleChannelNameChange = ({currentTarget: {value}}: ChangeEvent<HTMLInputElement>) => {
		setChannelName(value);
	};

	const handleVotingSystemChange = (value: string) => {
		setVotingSystem(value);
	};

	// reset states if component unmounts
	useEffect(() => () => {
		setLoading(false);
	}, [setLoading])

	return (
		<Space align="center" direction="vertical" style={{display: 'block', maxWidth: '400px', textAlign: 'center', flexShrink: '1'}}>
			<Title level={2} style={{marginBottom: 30}}>Choose a name and a voting system for your game.</Title>
			<Input style={{marginBottom: 20}} size="large" placeholder="Channel Name" onChange={handleChannelNameChange}/>
			<Select style={{width: '100%', marginBottom: 20}} size="large" defaultValue={VotingSystem.one} onChange={handleVotingSystemChange}>
				<Select.Option value={VotingSystem.one}>{VotingSystem.one}</Select.Option>
				<Select.Option value={VotingSystem.two}>{VotingSystem.two}</Select.Option>
				<Select.Option value={VotingSystem.three}>{VotingSystem.three}</Select.Option>
			</Select>
			<Button style={{ width: '100%' }} onClick={startNewPlanning} disabled={!channelName} type="primary" shape="round" size="large" loading={loading}>Create game</Button>
		</Space>
	);
};

export default NewGameForm;
