import React from "react";
import { Space } from "antd";

import NewGameForm from "../../Components/NewGameForm";

const NewGame: React.FC = () => {
	return (
		<Space align="center" direction="vertical" style={{justifyContent: 'center', flex: '1 1 auto'}}>
			<NewGameForm/>
		</Space>
	);
};

export default NewGame;
