import axios from 'axios';
import { ChannelDataInterface } from "../Interfaces";

const ENV:NodeJS.ProcessEnv = process.env;
const AxiosConfig = {
    headers: {
        'content-type': 'application/json; charset=utf-8',
    },
};

const ApiService = {
    createChannel: (channelData: ChannelDataInterface) => {
        return axios.post(`//${ENV.REACT_APP_POKER_APP_SERVER}/create`, channelData, AxiosConfig);
    },
    fetchChannel: (channelSlug: string) => {
        return axios.get(`//${ENV.REACT_APP_POKER_APP_SERVER}/channel/${channelSlug}`, AxiosConfig);
    },
};

export default ApiService;
