import io, { Socket } from "socket.io-client";

const getSocketIoConnection = (query?: object): Socket => {
	return io(process.env.REACT_APP_POKER_APP_SERVER || 'localhost:3001', query);
};

const SocketIoService = {
	getSocketIoConnection
};

export default SocketIoService;
