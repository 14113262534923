import { useRecoilValue } from "recoil";
import React, { useEffect, useState } from "react";
import { Layout, Space, Typography } from "antd";
import { Link } from "react-router-dom";

import { channelState } from "../States/GlobalStates";

import Logo from "../Assets/Logo.svg";

const { Header } = Layout;
const { Title } = Typography;

const AppHeader: React.FC = () => {
	const channel = useRecoilValue(channelState);
	const [windowScroll, setWindowScroll] = useState(false);
	const handleScroll = () => {
		setWindowScroll(window.scrollY > 10);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Header className={`${windowScroll ? 'ant-layout-header--scroll' : ''}`}>
			<Link to="/">
				<img src={Logo} alt="Planning Poker" width="130"/>
			</Link>
			<Space style={{marginLeft: 20}}>
				<Title level={4} style={{marginBottom: 0}}>{channel?.title}</Title>
			</Space>
		</Header>
	);
};

export default AppHeader;
