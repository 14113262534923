import React, { Fragment, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { channelState, meState, showCardsIntervalState, showCardsState, socketIoState } from "../States/GlobalStates";
import { Footer } from "antd/es/layout/layout";

const VotingCards: React.FC = () => {
	const showCardsInterval = useRecoilValue(showCardsIntervalState);
	const showCards = useRecoilValue(showCardsState);
	const me = useRecoilValue(meState);
	const socketState = useRecoilValue(socketIoState);
	const channel = useRecoilValue(channelState);

	useEffect(() => {
		if(me?.spectator && me?.vote !== '') {
			socketState?.emit('personVoted', { id: me?.id, vote: '' });
		}
		// eslint-disable-next-line
	}, [me]);

	const handleVote = (event: React.MouseEvent<HTMLButtonElement>, vote: string) => {
		let votingValue = '';
		if(vote !== me?.vote) {
			votingValue = vote ?? '';
		}
		socketState?.emit('personVoted', { id: me?.id, vote: votingValue });
	}

	return (
		<Fragment>
			{!showCardsInterval && !showCards && !me?.spectator && (
				<Footer className="voting-cards-wrapper">
					<div className="voting-cards">
						{channel?.votingSystem.split(',').map((vote, index) => (
							<button key={`${index}-${vote}`} disabled={showCards || showCardsInterval} className={`voting-cards__card${me?.vote === vote ? ' voting-cards__card--active': ''}`} onClick={(event) => handleVote(event, vote)}>{vote}</button>
						))}
					</div>
				</Footer>
			)}
		</Fragment>
	);
};

export default VotingCards;
