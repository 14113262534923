import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import AppHeader from "./Components/AppHeader";
import HomePage from './Pages/Home';
import NewGame from "./Pages/NewGame";
import PokerGame from "./Pages/PokerGame";

const {Content, Footer} = Layout;

const App = () => {
	const locationData = useLocation();
	const [pageClass, setPageClass] = useState('');

	useEffect(() => {
		setPageClass(locationData.pathname.split('/')[1]);
	}, [locationData]);

	return (
		<Layout className={pageClass}>
			<AppHeader/>
			<Content>
				<Routes>
					<Route path="/" element={<HomePage/>}/>
					<Route path="/new-game" element={<NewGame/>}/>
					<Route path="/poker/:channelSlug" element={<PokerGame/>}/>
					<Route path="*" element={<Navigate to="/" replace={true} />}/>
				</Routes>
			</Content>
			<Footer style={{textAlign: 'center', padding: '10px 0'}}>©{`${new Date().getFullYear()}`} Made with ♥️ by Sebastian Richter - <a href="https://raphael-gmbh.de/impressum" target="_blank" rel="noreferrer">Imprint</a> - <a href="https://raphael-gmbh.de/datenschutz" target="_blank" rel="noreferrer">Privacy</a></Footer>
		</Layout>
	)
};

export default App;
