import React, { ChangeEvent } from "react";
import { Button, Input, Modal, Switch } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { meState, nameModalState, nameState, socketIoState, spectatorModeState } from "../States/GlobalStates";
import { useParams } from "react-router-dom";

const PlayerModal: React.FC = () => {
	const params = useParams();
	const [name, setName] = useRecoilState(nameState);
	const [spectatorMode, setSpectatorMode] = useRecoilState(spectatorModeState);
	const [nameModalOpen, setNameModalOpen] = useRecoilState(nameModalState);
	const me = useRecoilValue(meState);
	const socketState = useRecoilValue(socketIoState);

	const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.currentTarget.value);
	};

	const handleSpectatorMode = (value: boolean) => {
		setSpectatorMode(value);
	};

	const handlePlayerSettingsClose = () => {
		if (me?.name) {
			if (name !== me.name) {
				setName(me.name);
			}
			if (spectatorMode !== me.spectator) {
				setSpectatorMode(me.spectator);
			}
			setNameModalOpen(false);
		}
	};

	const handlePlayerSettingsSave = () => {
		let localData = {
			name: name.trim(),
			spectator: spectatorMode
		};
		localStorage.setItem(`${params?.channelSlug}`, JSON.stringify(localData));

		socketState?.emit('personChange', {id: me?.id, name, spectator: spectatorMode});
		setNameModalOpen(false);
	};

	return (
		<Modal title="Hey there 👋🏻" open={nameModalOpen} centered={true} closable={true} destroyOnClose={true} onCancel={handlePlayerSettingsClose} footer={[
			<Button key="back" disabled={!socketState || !name.trim()} onClick={handlePlayerSettingsSave}>Save</Button>
		]}>
			<h4>What's your name?</h4>
			<Input style={{width: '100%', marginBottom: 20}} maxLength={20} required defaultValue={me?.name} value={name} size="large" placeholder="Your Name" onChange={handleNameChange}/>

			<h4>Spectator Mode?</h4>
			<Switch defaultChecked={me?.spectator} checked={spectatorMode} onChange={handleSpectatorMode}/>
		</Modal>
	);
};

export default PlayerModal;
